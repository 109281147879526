
import BaseResponse from './BaseResponse';

class LanguageListResponse extends BaseResponse {

    constructor(languages, responseCode) {

        super(responseCode);
        this.languages = languages;
    }
    
}

export default LanguageListResponse;