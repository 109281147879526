import React, { Component } from 'react';
import './../../../../styles/adm/files.scss';
import { Spinner } from './../../../shared/Spinner';
import { Button } from './../../../shared/Button';
import axios from "axios";
import AdmResponseHelper from './../../../../model/helper/AdmResponseHelper';
import { FilterFiles } from './components/FilterFiles';
import { NewFile } from './components/NewFile';
import { EditFile } from './components/EditFile';

export class Files extends Component {
    static displayName = Files.name;

    constructor(props) {
        super(props);

        this.defaultFilters = {
            extension: "all",
            name: "",
            paginationCount: 25,
            paginationPage: 1
        };

        this.state = {

            filters: this.defaultFilters,

            newFormOpen: false,
            editFormOpen: false,
            editFileData: null,

            fileList: [],
            extensionList: [],

            responseList: null,
            responseDelete: null,
            responseExtensionList: null,

            loading: true
        }

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        this.GetExtensionList();
        this.GetFileList();
    }

    GetExtensionList = () => {

        this.setState((prevState) => ({ ...prevState, loading: true }));

        return axios
            .get(`/api/adm/file/${this.props.type}/extension/list`, this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.setState((prevState) => ({ ...prevState, extensionList: data.extensionList, responseExtensionList: data.responseCode }));
                if (data.responseCode != 200)
                    return;

            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, responseExtensionList: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    DeleteFile = (file) => {

        this.setState((prevState) => ({ ...prevState, loading: true }));
        let fullName = this.FileGetFullName(file);

        return axios
            .delete(`/api/adm/file/${this.props.type}/delete/${fullName}`, this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.setState((prevState) => ({ ...prevState, responseDelete: data.responseCode }));
                if (data.responseCode == 200) {
                    this.GetFileList();
                    this.HandleCloseForms();
                }    

            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, responseDelete: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    GetFileList = (filters = this.state.filters) => {

        this.setState((prevState) => ({ ...prevState, loading: true }));

        let extension = filters.extension == 'all' ? null : filters.extension;
        let paginationCount = filters.paginationCount == 'all' ? null : filters.paginationCount;

        return axios
            .post(`/api/adm/file/${this.props.type}/list`, {
                Extension: extension,
                Name: filters.name,
                PaginationCount: paginationCount,
                PaginationPage: filters.paginationPage
            }, this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.setState((prevState) => ({ ...prevState, responseList: data.responseCode, fileList: data.fileList, allRowsCount: data.allRowsCount }));
                if (data.responseCode != 200)
                    return;

                if (this.state.editFormOpen && this.state.editFileData != null)
                    this.HandleOpenEditForm(this.state.editFileData.name);

            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, responseList: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    RenderResponse = () => {

        switch (this.state.response) {
            case 400:
            case 401:
                return this.responseHelper.RenderLoginInvalid();
            case 500:
                return this.responseHelper.RenderSomethingWentWrong();
            default:
                return;
        }
    }

    RenderErrorResponse = () => {
        switch (this.state.responseList) {
            case 401:
                return this.responseHelper.RenderLoginInvalid();
            case 500:
                return this.responseHelper.RenderSomethingWentWrong();
            default:
                return;
        }
    }

    HandleSetFilters = (newFilters) => {
        this.setState((prevState) => ({ ...prevState, filters: newFilters, responseDelete: null }));
        this.GetFileList(newFilters);
    }

    HandleOpenNewForm = () => {
        this.HandleCloseForms();
        this.setState((prevState) => ({ ...prevState, newFormOpen: true }));
    }

    HandleCloseForms = () => {
        this.setState((prevState) => ({ ...prevState, newFormOpen: false, editFormOpen: false }));
    }

    HandleOpenEditForm = (name) => {

        this.HandleCloseForms();

        if (name == null)
            return;

        let targetFile = this.state.fileList.find(f => f.name === name);

        this.setState((prevState) => ({ ...prevState, editFormOpen: true, editFileData: targetFile }));
    }

    RenderForms = () => {

        if (this.state.editFormOpen && this.state.editFileData == null)
            return

        return (
            (this.state.newFormOpen || this.state.editFormOpen) &&
            <div className='position-fixed h-100 formGlass d-flex justify-content-center pt-5'>
                {this.state.newFormOpen &&
                    <NewFile type={this.props.type} extensionList={this.state.extensionList} HandleCloseForm={() => this.HandleCloseForms()} loggedAdmin={this.props.loggedAdmin} GetFileList={() => this.GetFileList()} />
                }
                {this.state.editFormOpen &&
                    <EditFile responseDelete={this.state.responseDelete} extensionList={this.state.extensionList} type={this.props.type} DeleteFile={(name, extension) => this.DeleteFile(name, extension)} HandleCloseForm={() => this.HandleCloseForms()} loggedAdmin={this.props.loggedAdmin} targetFile={this.state.editFileData} GetFileList={() => this.GetFileList()} />
                }
            </div>
        );
    }

    DownloadFile = (file) => {
        let fullName = this.FileGetFullName(file);
        let fullPath = `/files/${this.props.type}s/${fullName}`;
        const a = document.createElement('a');
        a.setAttribute('download', fullName);
        a.setAttribute('href', fullPath);
        a.click();
    };

    RenderFileList = () => {

        return (
            this.state.fileList.map((file) => {
                return <div className='col-12 d-flex border-top border-bottom align-items-center py-2' role='button' key={this.FileGetFullName(file)} onClick={() => this.HandleOpenEditForm(file.name)} >
                    <div className='col-4 colMinW'>{file.name}</div>
                    <div className='col-1'>{file.extension}</div>
                    {this.props.type == 'image' &&
                        <div className='col-3 d-flex align-items-center'>
                            <img src={
                                file.extension == '.pdf' ?
                                    `files/pdf.png`
                                    :
                                    `files/${this.props.type}s/${this.FileGetFullName(file)}`

                            } alt='preview obrázku' />
                        </div>       
                    }
                    <div className='col-2' onClick={(e) => e.stopPropagation() }>
                        <Button text={"Stáhnout"} small OnClick={() => this.DownloadFile(file) } />
                    </div>
                    <div className='col-2' onClick={(e) => e.stopPropagation()}>
                        <Button text={"Smazat"} small OnClick={() => this.DeleteFile(file) } />
                    </div>
                </div>
            })
        );
    }

    FileGetFullName = (file) => {
        return `${file.name}${file.extension}`;
    }

    render() {
        return (
            <div id='admFiles' className='position-relative'>
                <div className='mt-3 d-flex align-items-center'>
                    <h1 className='m-0 me-4'>Správa souborů</h1>
                    <Button text={" + Přidat"} OnClick={() => this.HandleOpenNewForm()} />
                </div>
                <ul className='mt-2'>
                    <li>
                        <span className='me-2'>Podporované formáty:</span>
                        {this.state.extensionList.map((e, i) => {
                            return (
                                <span className='' key={e}>
                                    <span className='fw-bold'>{e}</span>
                                    {this.state.extensionList.length - 1 != i &&
                                        <span className='mx-1'>|</span>    
                                    }
                                </span>
                            );
                        })}
                    </li>
                    <li>V názvu souboru nesmí být mezery</li>
                </ul>
                {this.responseHelper.RenderDeleteResponse(this.state.responseDelete)}
                {this.RenderErrorResponse()}
                <div className='mt-4'>
                    <FilterFiles extensionList={this.state.extensionList} fileListCount={this.state.fileList.length} defaultFilters={this.defaultFilters} filters={this.state.filters} HandleSetFilters={(f) => this.HandleSetFilters(f)} loggedAdmin={this.props.loggedAdmin} allRowsCount={this.state.allRowsCount} />
                    <div className='position-relative'>
                        {this.state.loading ?
                            <Spinner dark />
                            :
                            <>
                                <div className='col-12 d-flex mt-3'>
                                    <div className='col-4 fw-bold colMinW'>Název</div>
                                    <div className='col-1 fw-bold'>Typ</div>
                                    {this.props.type == 'image' &&
                                        <div className='col-3 fw-bold'>Preview</div>
                                    }
                                    <div className='col-2 fw-bold'>Download</div>
                                    <div className='col-2 fw-bold'>DEL</div>
                                </div>
                                <div className='col-12 fileList mb-4'>
                                    {this.RenderFileList()}
                                </div>
                            </>
                        }
                    </div>
                </div>
                {this.RenderForms()}
            </div>
        );
    }
}
