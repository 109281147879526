import React, { Component } from 'react';
import './../../../styles/public/brochures.scss';
import { BreadCrumbs } from './BreadCrumbs';

export class Brochures extends Component {
    static displayName = Brochures.name;

    constructor(props) {
        super(props);

        this.breadCrumbPath = [
            { text: this.props.translate("s_navlink_brochures"), link: "" },
        ];

        this.mobileBreakpoint = 554;

        this.state = {
            brochures: {
                ad: [           
                    { name: this.props.translate("brochures_main_brochure_name"), path: "s_ad_main_brochure", img: "TB_AutomatedDispensary_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() },
                    { name: "TB 300", path: "s_ad_tb_300_brochure", img: "TB_300_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() },
                    { name: "TB COMBI", path: "s_ad_tb_combi_brochure", img: "TB_COMBI_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() },
                    { name: "TB LOCKER 24", path: "s_ad_tb_locker24_brochure", img: "TB_LOCKER24_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() },
                    { name: "TB LOCKER 14", path: "s_ad_tb_locker14_brochure", img: "TB_LOCKER14_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() },
                    { name: "TB DRAWER", path: "s_ad_tb_drawer_brochure", img: "TB_DRAWER_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() },
                    { name: "TB GLOVES", path: "s_ad_tb_gloves_brochure", img: "TB_GLOVES_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() },
                    { name: "EPV 27", path: "s_ad_epv_27_brochure", img: "EVP27_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() }
                ],
                vm: [
                    { name: this.props.translate("brochures_product_program"), path: "s_vm_product_program_brochure", img: "VendingMachines_product_program_preview.jpg", horizontal: true, shown: !this.IsMobile(), ref: React.createRef() }
                ]
            },
            vmHeading: { shown: false, ref: React.createRef()}
        }
    }

    componentDidMount() {
        document.title = "Sielaff Bohemia - " + this.props.translateRaw("s_navlink_brochures");
        window.scrollTo({ top: 0 });

        // different fadein animations on mobile and pc
        if (this.IsMobile()) {
            window.addEventListener('scroll', this.HandleScroll);
            this.HandleScroll();
        } else {
            this.BrochuresFadeIn();
        }
    }

    HandleScroll = () => {

        this.state.brochures.ad.forEach((brochure, index) => {

            if (brochure.shown)
                return;

            this.setState((prevState) => {
                // Create a copy of the current state
                let updatedBrochures = { ...prevState.brochures };

                // Update the specific brochure's shown property
                updatedBrochures.ad[index] = {
                    ...updatedBrochures.ad[index],
                    shown: this.ShowState(brochure.ref)
                };

                // Return the updated state
                return { brochures: updatedBrochures };
            });
        });

        this.state.brochures.vm.forEach((brochure, index) => {

            if (brochure.shown)
                return;

            this.setState((prevState) => {
                // Create a copy of the current state
                let updatedBrochures = { ...prevState.brochures };

                // Update the specific brochure's shown property
                updatedBrochures.vm[index] = {
                    ...updatedBrochures.vm[index],
                    shown: this.ShowState(brochure.ref)
                };

                // Return the updated state
                return { brochures: updatedBrochures };
            });
        });

        if (!this.state.vmHeading.shown)
            this.setState((prevState) => ({
                vmHeading: { ...prevState.vmHeading, shown: this.ShowState(this.state.vmHeading.ref) }
            }));
    }

    ShowState = (ref) => {

        if (ref == null || ref.current == null)
            return false;

        return ref.current.getBoundingClientRect().top < (window.screen.height * (this.IsMobile() ? 0.86 : 0.8));
    }

    IsMobile = () => {
        return window.innerWidth < this.mobileBreakpoint;
    }

    BrochuresFadeIn = () => {
        this.state.brochures.ad.forEach((brochure, index) => {
            setTimeout(() => {
                this.setState((prevState) => {
                    // Copy the previous state's brochures
                    let updatedBrochures = { ...prevState.brochures };

                    // Update the specific brochure's shown property
                    updatedBrochures.ad[index] = {
                        ...updatedBrochures.ad[index],
                        shown: true
                    };

                    // Return the updated state
                    return { brochures: updatedBrochures };
                });
            }, index * 100); // Delay each by 100ms multiplied by the index
        });
    }


    RenderBrochure = (heading, pdfName, image, horizontal, shown, ref) => {

        let pdfPath = `/files/documents/${this.props.translateRaw(pdfName)}`;
        let showAnimation = shown ? (this.IsMobile() ? 'flyIn' : 'fadeIn') : '';

        return <div className={`brochure fitW mb-2 mb-md-3 p-3 flyInAnimation ${showAnimation}`} key={heading} ref={ref}>
            <a href={pdfPath} target='_blank' rel='noreferrer'>
                <div className={`img ${horizontal ? 'horizontal' : 'vertical'}`}>
                    <img src={`/files/images/${image}`} alt='Pdf preview' />
                </div>
                <h4 className='mt-2 mb-0'>{heading}</h4>
            </a>
            <a href={pdfPath} download className='redText fitW download d-flex align-items-center'>
                <i className="fa-solid fa-download me-2 redText"></i>
                {this.props.translate("s_download")}
            </a>
        </div>
    }

    RenderBrochures = (brochureList) => {
        return <div className='d-flex flex-wrap justify-content-center justify-content-md-start'>
            {brochureList.map((b) =>
                this.RenderBrochure(b.name, b.path, b.img, b.horizontal, b.shown, b.ref)
            )}
        </div>
    }

    RenderVmHeading = () => {

        let showAnimation = '';
        if (this.IsMobile()) {
            showAnimation = this.state.vmHeading.shown ? 'flyInAnimation flyIn' : 'flyInAnimation';
        } 
                 
        return <h2 className={`mt-4 mb-3 ${showAnimation}`} ref={this.state.vmHeading.ref}>{this.props.translate("brochures_subheading_vending_machines")}</h2>
    }

    render() {
        return (
            <div id='brochures' className='containerCustomMain pt-3' ref={this.props.navLinks.brochures.main.ref}>
                <BreadCrumbs
                    translate={this.props.translate}
                    navLinks={this.props.navLinks}
                    HandleLinkClick={this.props.HandleLinkClick}
                    path={this.breadCrumbPath}
                />
                <h1 className='mt-4'>{this.props.translate("brochures_heading")}</h1>
                <h2 className='mb-3'>{this.props.translate("brochures_subheading_automated_dispensary")}</h2>
                {this.RenderBrochures(this.state.brochures.ad)}
                {this.RenderVmHeading()}
                {this.RenderBrochures(this.state.brochures.vm)}
            </div>
        );
    }
}
