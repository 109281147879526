import React, { useState, useEffect, useRef } from 'react';
import '../../styles/shared/showMore.scss';

function ShowMore({ text, label }) {
    const [opened, setOpened] = useState(false);
    const [textHeight, setTextHeight] = useState(0);
    const showMoreRef = useRef(null);

    useEffect(() => {
        if (showMoreRef.current) {
            setTextHeight(showMoreRef.current.scrollHeight);
        }
    }, []);

    const HandleToggleOpened = () => {
        setOpened(!opened);
    };

    return (
        <>
            <div className='showMore d-flex flex-column'>
                <div role="button" className='toggler mb-2 redText d-flex align-items-center fitW' onClick={HandleToggleOpened}>
                    {label}
                    <i className={`ms-1 redText fa-solid fa-chevron-up ${!opened ? 'down' : ''}`}></i>
                </div>
                <div
                    className='containerCustomSm text'
                    ref={showMoreRef}
                    style={{
                        height: opened ? `${textHeight}px` : '0px',
                    }}
                >
                    {text}
                </div>
            </div>
        </>
    );
}

export default ShowMore;
