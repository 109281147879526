import React, { Component } from 'react';
import '../../styles/shared/footer.scss'; 

export class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
        super(props);
    }

    ScrollTop = () => {
        window.scroll(0, 0);
    }

    render() {
        return (
            <div className='footer pb-3 pt-5 position-relative mt-5'>
                <div className='container d-flex flex-column align-items-start align-items-sm-center justify-content-center'>
                    <h2>Sielaff Bohemia s. r. o.</h2>
                    <div>{this.props.translate("s_footer_group_member")}</div>
                    <div className='contact my-3 d-flex align-items-center' role="button" onClick={() => this.props.HandleLinkClick(this.props.navLinks.contact.main)}>
                        <div className='separator me-3'>|</div>
                        {this.props.translate("s_navlink_contact")}
                        <div className='separator ms-3'>|</div>
                    </div>
                    <div className='mb-5'>{this.props.translate("s_footer_legislative_info")}</div>
                    <div>{this.props.translate("s_footer_copyright")}</div>
                    <div>{this.props.translate("s_footer_cookies")}</div>
                    <div>{this.props.translate("s_footer_ilustration_example")}</div>
                    <span className='madeBy'>Made by Daniel Pavíza</span>
                </div>
            </div>
        );
    }
}
