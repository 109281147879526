
import LocalStorageHandler from './LocalStorageHandler';
import LoginResponse from './../response/LoginResponse';
import axios from "axios";

class LoginController {

    lsh = new LocalStorageHandler();

    AdminCredentialsLogin = async (username, password) => {

        return axios
            .post("/api/adm/user/login", {
                Username: username,
                Password: password
            })
            .then((response) => {

                let data = response.data;
                if (data.responseCode == 200)
                    this.lsh.SetLoginToken(data.user.loginToken);

                return new LoginResponse(data.user, data.responseCode);

            }).catch(() => {
                return new LoginResponse(null, 500);
            });
    }

    GuestCredentialsLogin = async (username, password) => {

        return axios
            .post("/api/public/guest/login", {
                Username: username,
                Password: password
            })
            .then((response) => {

                let data = response.data;
                if (data.responseCode == 200)
                    this.lsh.SetLoginToken(data.guest.loginToken);

                return new LoginResponse(data.guest, data.responseCode);

            }).catch(() => {
                return new LoginResponse(null, 500);
            });
    }

    AdminTokenLogin = async () => {

        let loginToken = this.lsh.GetLoginToken();
        if (loginToken == null)
            return new LoginResponse(null, 401);

        return axios
            .post("/api/adm/user/login", {
                Token: loginToken
            })
            .then((response) => {
                let data = response.data;
                return new LoginResponse(data.user, data.responseCode);
            }).catch((response) => {

                if (localStorage.getItem("debug"))
                    console.log(response);

                return new LoginResponse(null, 500);
            });
    }

    GuestTokenLogin = async () => {

        let loginToken = this.lsh.GetLoginToken();
        if (loginToken == null)
            return new LoginResponse(null, 401);

        return axios
            .post("/api/public/guest/login", {
                Token: loginToken
            })
            .then((response) => {
                let data = response.data;
                return new LoginResponse(data.guest, data.responseCode);
            }).catch((response) => {

                if (localStorage.getItem("debug"))
                    console.log(response);

                return new LoginResponse(null, 500);
            });
    }

    LogOff = async (token, id) => {

        let header = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: token })
            }
        };
        return axios
            .get(`/api/adm/user/logoff/${id}`, header)
            .then((response) => {
                let data = response.data;
                this.lsh.SetLoginToken(null);
                return { responseCode: data.responseCode };
            }).catch(() => {
                return { responseCode: 500 }
            });
    }

    GuestLogOff = async (loginToken) => {

        return axios
            .post(`/api/public/guest/logoff`, { LoginToken: loginToken })
            .then((response) => {
                let data = response.data;
                if(data.responseCode == 200)
                    this.lsh.SetLoginToken(null);
                return { responseCode: data.responseCode };
            }).catch((ex) => {
                return { responseCode: 500 }
            });
    }
}

export default LoginController;