import React, { Component } from 'react';
import './../../../styles/public/automatedDispensariesDetail.scss';
import { Button } from '../../shared/Button';
import { ProductShowcase } from '../../shared/ProductShowcase';
import ShowMore from '../../shared/ShowMore';
import { BreadCrumbs } from './BreadCrumbs';

export class AutomatedDispensariesDetail extends Component {
    static displayName = AutomatedDispensariesDetail.name;

    constructor(props) {
        super(props);
        this.translate = this.props.translate;
        this.translateRaw = this.props.translateRaw;

        this.products = [
            {
                heading: this.translate("ad_tb_300_heading"),
                subheading: this.translate("ad_tb_300_subheading"),
                points: this.translate("ad_tb_300_points"),
                text: this.translate("ad_tb_300_text"),
                download: this.translateRaw("s_ad_tb_300_brochure"),
                imgMain: "product_tb_300_main.jpg",
                imgVer: "product_tb_300_vertical.jpg",
                imgHor: "product_tb_300_horizontal.jpg",
                showAnimationDone: false,
            },
            {
                heading: this.translate("ad_tb_combi_heading"),
                subheading: this.translate("ad_tb_combi_subheading"),
                points: this.translate("ad_tb_combi_points"),
                text: this.translate("ad_tb_combi_text"),
                download: this.translateRaw("s_ad_tb_combi_brochure"),
                imgMain: "product_tb_combi_main.jpg",
                imgVer: "product_tb_combi_vertical.jpg",
                imgHor: "product_tb_combi_horizontal.jpg"
            },
            {
                heading: this.translate("ad_tb_locker24_heading"),
                subheading: this.translate("ad_tb_locker24_subheading"),
                points: this.translate("ad_tb_locker24_points"),
                text: this.translate("ad_tb_locker24_text"),
                download: this.translateRaw("s_ad_tb_locker24_brochure"),
                imgMain: "product_tb_locker_24_main.jpg",
                imgVer: "product_tb_locker_24_vertical.jpg",
                imgHor: "product_tb_locker_24_horizontal.jpg"
            },
            {
                heading: this.translate("ad_tb_locker14_heading"),
                subheading: this.translate("ad_tb_locker14_subheading"),
                points: this.translate("ad_tb_locker14_points"),
                text: this.translate("ad_tb_locker14_text"),
                download: this.translateRaw("s_ad_tb_locker14_brochure"),
                imgMain: "product_tb_locker_14_main.jpg",
                imgVer: "product_tb_locker_14_vertical.jpg",
                imgHor: "product_tb_locker_14_horizontal.jpg"
            },
            {
                heading: this.translate("ad_tb_drawer_heading"),
                subheading: this.translate("ad_tb_drawer_subheading"),
                points: this.translate("ad_tb_drawer_points"),
                text: this.translate("ad_tb_drawer_text"),
                download: this.translateRaw("s_ad_tb_drawer_brochure"),
                imgMain: "product_tb_drawer_main.jpg",
                imgVer: "product_tb_drawer_vertical.jpg",
                imgHor: "product_tb_drawer_horizontal.jpg"
            },
            {
                heading: this.translate("ad_tb_gloves_heading"),
                subheading: this.translate("ad_tb_gloves_subheading"),
                points: this.translate("ad_tb_gloves_points"),
                text: this.translate("ad_tb_gloves_text"),
                download: this.translateRaw("s_ad_tb_gloves_brochure"),
                imgMain: "product_tb_gloves_main.jpg",
                imgVer: "product_tb_gloves_vertical.jpg",
                imgHor: "product_tb_gloves_horizontal.jpg"
            },
            {
                heading: this.translate("ad_epv_27_heading"),
                subheading: this.translate("ad_epv_27_subheading"),
                points: this.translate("ad_epv_27_points"),
                text: this.translate("ad_epv_27_text"),
                download: this.translateRaw("s_ad_epv_27_brochure"),
                imgMain: "product_epv_27_main.jpg",
                imgVer: "product_epv_27_vertical.jpg",
                imgHor: "product_epv_27_horizontal.jpg"
            }
        ];

        this.breadCrumbPath = [
            { text: this.translate("s_navlink_dispensary"), link: this.props.link.main},
            { text: this.translate("s_dispensary_machines"), link: ""},
        ];

        this.downloadBrochureText = this.translate("ad_download_brochure_text");
    }

    componentDidMount() {
        window.scrollTo({ top: 0 });
        document.title = "Sielaff Bohemia - " + this.translateRaw("s_dispensary_machines");
    }

    render() {
        return (
            <div id='automatedDispensariesDetail' className='containerCustomMainBigger' ref={this.props.link.subLinks.adDetail.ref}>
                <section className='intro position-relative pb-0 pb-md-4'>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex flex-column col-12 col-md-7 col-xl-6 mt-3'>
                            <BreadCrumbs
                                translate={this.props.translate}
                                navLinks={this.props.navLinks}
                                HandleLinkClick={this.props.HandleLinkClick}
                                path={this.breadCrumbPath}
                            />
                            <div className='d-flex flex-column mt-4'>
                                <h1 className='d-flex align-items-center'>
                                    <img src='/files/images/tb.png' alt='logo TB' className='me-3' />
                                    {this.props.translate("ad_heading")}
                                </h1>
                                <div className='d-flex flex-column col justify-content-between'>
                                    <div className='d-flex flex-column col mb-2'>
                                        <span className='mb-1'>{this.props.translate("ad_text")}</span>
                                        <ul>
                                            {this.props.translate("ad_points")}
                                        </ul>
                                        <ShowMore text={this.props.translate("ad_text_more")} label={this.props.translate("ad_show_more")} />
                                    </div>
                                    <div className='mt-2'>
                                        <Button iconClass={"fa-regular fa-file-pdf"} text={this.downloadBrochureText} downloadFile={this.props.translateRaw("s_ad_main_brochure")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bgImg d-none d-md-flex col ms-5'></div>
                    </div>
                </section>
                <section className='adList mt-5 d-flex flex-column'>
                    <h2 className='d-flex align-items-center m-0'>
                        {this.props.translate("ad_subheading")}
                    </h2>
                    {Object.keys(this.products).map((key, i) => {
                        return <ProductShowcase product={this.products[key]} key={i} translate={this.props.translate} downloadBrochureText={this.downloadBrochureText} />
                    })}
                </section>
            </div>
        );
    }
}
