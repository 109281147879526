import React, { Component } from 'react'; 
import { Button } from './../../../../shared/Button';
import { Spinner } from './../../../../shared/Spinner';
import { InfoMessage } from './../../../../shared/InfoMessage';
import axios from "axios";
import AdmResponseHelper from './../../../../../model/helper/AdmResponseHelper';

export class EditFile extends Component {
    static displayName = EditFile.name;

    constructor(props) {
        super(props);

        this.state = {
            name: this.props.targetFile.name,
            nameEmpty: false,
            nameHasSpace: false,

            newFile: [],
            invalidExtension: false,

            responseEdit: null,
            responseDelete: null
        };

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        document.body.classList.add('noScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noScroll');
    }

    HandleSetName = (val) => {
        this.setState((prevState) => ({ ...prevState, name: val, responseDelete: null, responseEdit: null }));
    }

    FileToByteArray = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const arrayBuffer = reader.result;
                const byteArray = new Uint8Array(arrayBuffer);
                resolve(Array.from(byteArray)); // Convert Uint8Array to regular array
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
    };

    EditFile = async() => {

        if (this.state.invalidExtension)
            return;

        let nameEmpty = this.state.name.length <= 0;
        let nameHasSpace = this.state.name.includes(" ");

        this.setState((prevState) => ({ ...prevState, loading: !(nameEmpty || nameHasSpace), nameEmpty: nameEmpty, nameHasSpace: nameHasSpace }));

        if (nameEmpty || nameHasSpace)
            return;

        let byteFile = await this.FileToByteArray(this.state.newFile);

        axios
            .patch(`/api/adm/file/${this.props.type}/edit`, {
                OldName: this.props.targetFile.name,
                NewName: this.state.name,
                Extension: this.props.targetFile.extension,
                NewFile: byteFile
            }, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                if (data.responseCode == 200) {
                    this.props.GetFileList();
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 200);
                }      

                this.setState((prevState) => ({
                    ...prevState,
                    responseEdit: data.responseCode,
                }));

            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, responseEdit: 400 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    HandleImportFile = (file) => {

        if (!file) {
            this.setState((prevState) => ({ ...prevState, newFile: [], invalidExtension: false }));
            return;
        }

        console.log(file)

        let invalidExtension = this.props.targetFile.extension != this.GetFileExtension(file);
        this.setState((prevState) => ({ ...prevState, newFile: file, invalidExtension: invalidExtension }));
    }

    GetFileExtension = (file) => {
        return !file ? null : `.${file.name.split('.').pop().toLowerCase()}`
    }

    render() {

        let imgPath;
        if (this.props.type == 'document') {
            imgPath = this.props.targetFile.extension == '.pdf' ? `/files/images/pdf.png` : `/files/images/default.png`;
        } else {
            imgPath = `/files/images/${this.props.targetFile.name}${this.props.targetFile.extension}`;
        }

        return (
            <div className='form border rounded p-3 fitH'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0'>Upravit soubor</h2>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className='p-2 d-flex align-items-center justify-content-center'>
                        <img className='editImg' src={imgPath} alt='soubor' />
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12 ${(this.state.nameEmpty || this.state.nameHasSpace) && 'inputError'}`}>
                        <label htmlFor='name'>Název</label>
                        <input type='text' id='name' className='ps-2' value={this.state.name} onChange={(e) => this.HandleSetName(e.target.value)} />
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='extension'>Formát <i className="ms-2 fa-solid fa-lock"></i></label>
                        <input type='text' id='extension' className='ps-2 readonly' value={this.props.targetFile.extension} readOnly/>
                    </div>
                    <div className={`d-flex flex-column col-12 mb-3`}>
                        <label htmlFor='file' className=''>Přehrát soubor</label>
                        <input type='file' className='' id='file' accept={this.props.targetFile.extension} onChange={(e) => this.HandleImportFile(e.target.files[0])} />
                    </div>
                    <div className='d-flex justify-content-between pt-3'>
                        <Button className='col-2' text={'Smazat'} OnClick={() => this.props.DeleteFile(this.props.targetFile)} />
                        <div className='d-flex flex-column align-items-end'>
                            <div className='d-flex'>
                                <Button className='col-2' text={"Upravit"} OnClick={() => this.EditFile()} />
                            </div>
                        </div>
                    </div>
                    <div className={`d-flex flex-column mt-3`}>
                        {this.responseHelper.RenderEditResponse(this.state.responseEdit)}
                        {this.responseHelper.RenderDeleteResponse(this.props.responseDelete)}
                        {this.state.nameEmpty && <InfoMessage text={'Vyplňte název!'} />}
                        {this.state.nameHasSpace && <InfoMessage text={'V názvu nesmí být mezera!'} />}
                        {this.state.invalidExtension &&
                            <div className='d-flex flex-column redText'>
                                Formát nového souboru se musí shodovat s formátem starého! ({this.props.targetFile.extension})
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

}
