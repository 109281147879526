import React, { Component } from 'react';
import { Button } from './Button';
import '../../styles/shared/productShowcase.scss'; 
import ShowMore from './ShowMore';

export class ProductShowcase extends Component {
    static displayName = ProductShowcase.name;

    constructor(props) {
        super(props);

        this.mobileBreakpoint = 992;
        this.state = {
            isMobile: this.IsMobile(),
            shown: false,
            ref: React.createRef()
        };

        this.product = this.props.product;
    }

    componentDidMount() {
        window.addEventListener('resize', this.HandleResize);
        window.addEventListener('scroll', this.HandleScroll);
        this.HandleScroll();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.HandleResize);
        window.removeEventListener('scroll', this.HandleScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.shown && !prevState.shown)
            window.removeEventListener('scroll', this.HandleScroll);

        if (this.state.ref.current != null && prevState.ref.current == null)
            this.HandleScroll();
    }

    HandleResize = () => {
        let isMobile = this.IsMobile();
        if (this.state.isMobile != isMobile)
            this.setState((prevState) => ({ ...prevState, isMobile: isMobile }))
    }

    HandleScroll = () => {
        this.setState((prevState) => ({ ...prevState, shown: this.ShowState() }));
    }

    ShowState = () => {

        if (this.state.ref == null || this.state.ref.current == null)
            return false;

        return this.state.ref.current.getBoundingClientRect().top < (window.screen.height * (this.state.isMobile ? 0.86 : 0.8));
    }

    IsMobile = () => {
        return window.innerWidth < this.mobileBreakpoint;
    }

    RenderBrochureButton = () => {                       
        return <Button iconClass={"fa-regular fa-file-pdf"} text={this.props.downloadBrochureText} inverted downloadFile={this.product.download} />;
    }

    render() {
        return (
            <section className={`productShowcase d-flex flex-column flex-lg-row ${this.state.shown ? 'flyIn' : ''}`} ref={this.state.ref} >
                {this.state.isMobile &&
                    <h1 className='mb-4'>{this.product.heading}</h1>                                       
                }
                <div className='mainImg mx-auto mb-4'>
                    <img src={`files/images/${this.product.imgMain}`} alt={`Automated dispensary ${this.product.heading}`} className='' />
                </div>
                <div className='d-flex flex-column col'>
                    {!this.state.isMobile &&
                        <div className='borderTop pt-2 position-relative'>
                            <h1 className='mb-3'>{this.product.heading}</h1>
                            {this.product.download && 
                                <div className='position-absolute downloadAbsolute end-0'>
                                    {this.RenderBrochureButton()}
                                </div>
                            }
                        </div>
                    }
                    <h5 className='mb-1'>{this.product.subheading}</h5>
                    <ul>
                        {this.product.points}
                    </ul>
                    <ShowMore text={this.product.text} label={this.props.translate("ad_show_more")} />
                    <div className={`subImgs mt-4 d-flex flex-column align-items-center justify-content-center flex-md-row w-100 justify-content-lg-end ${this.product.imgHor ? '' : ''}`}>
                        {this.product.imgVer &&
                            <img src={`files/images/${this.product.imgVer}`} alt={`Automated dispensary ${this.product.heading} 2`} className={`ver ${this.product.imgHor ? 'mt-3' : 'showOnMobile me-auto ms-5'} mt-md-0 order-2`} />
                        }
                        {this.product.imgHor &&
                            (this.product.imgVer ?
                            <img src={`files/images/${this.product.imgHor}`} alt={`Automated dispensary ${this.product.heading} 3`} className='hor order-md-3' />
                                    :
                            <img src={`files/images/${this.product.imgHor}`} alt={`Automated dispensary ${this.product.heading} 3`} className='fill' />
                            )
                        }
                    </div>
                    {(this.product.download && this.state.isMobile) &&
                        <div className='mt-4'>
                            {this.RenderBrochureButton()}
                        </div>
                    }
                </div>
            </section>
        );
    }
}
