import React, { Component } from 'react';
import '../../styles/shared/backButton.scss';

export class BackButton extends Component {
    static displayName = BackButton.name;

    constructor (props) {
        super(props);
    }

  render() {
      return (
          <div className='d-flex'>
              <span className={`buttonBack d-flex ${!this.props.small && (this.props.isAdm ? "mb-2 mb-lg-3" : "mb-3 mb-lg-5")}`}
                  onClick={() => this.props.HandleLinkClick(this.props.backLink)}
              >
                  <img src='./files/images/arrow.png' alt='šipka zpět' className='' />
                  <span>{this.props.isAdm ?
                      this.props.text
                      :
                      this.props.text}
                  </span>
              </span>
          </div>
    );
  }
}
