import React, { Component } from 'react';
import '../../styles/shared/notFound.scss';  
import { BackButton } from './BackButton';
import { BreadCrumbs } from '../public/pages/BreadCrumbs';

export class NotFound extends Component {
    static displayName = NotFound.name;

    constructor(props) {
        super(props);

        this.breadCrumbPath = [
            { text: this.props.translate("s_not_found"), link: "" }
        ];
    }

    render() {
        return (
            <div id='notFound' className='position-relative containerCustomMainBigger mt-3'>
                <div className=''>
                    <BreadCrumbs
                        translate={this.props.translate}
                        navLinks={this.props.navLinks}
                        HandleLinkClick={this.props.HandleLinkClick}
                        path={this.breadCrumbPath}
                    />
                    <div className='mt-4 d-flex flex-column flex-md-row align-items-center justify-content-between'>
                        <div>
                            <h1 className='mb-2'>{this.props.translate("s_page_not_found")}</h1>
                            <h3>{this.props.translate("s_wrong_address")}</h3>
                        </div>
                    </div>
                    <div className='position-absolute questionmark'>
                        <i className="fa-solid fa-question"></i>
                    </div>
                </div>
            </div>
        );
    }
}
