
import BaseResponse from './BaseResponse';

class LoginResponse extends BaseResponse {

    constructor(user, responseCode) {

        super(responseCode);
        this.user = user;
    }
    
}

export default LoginResponse;