
import BaseResponse from './BaseResponse';

class TranslationListResponse extends BaseResponse {
    constructor(translations, responseCode) {
        super(responseCode);
        this.translations = translations;
    }
}

export default TranslationListResponse;