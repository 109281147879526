import React, { Component } from 'react';
import '../../../styles/public/breadCrumbs.scss';

export class BreadCrumbs extends Component {
    static displayName = BreadCrumbs.name;

    constructor (props) {
        super(props);
        this.mobileThreshold = 576;
    }

    EmptyFunction = () => {
        // This function does nothing
    }

    GetIsMobile = () => {
        return window.innerWidth <= this.mobileThreshold;
    }

    render() {
        return <div className={`breadCrumbs fitW d-flex ${this.GetIsMobile() ? 'flex-column' : 'align-items-center'}`}>
            {!this.props.noHome &&
                <div className='segment d-flex align-items-center' role="button" onClick={() => this.props.HandleLinkClick(this.props.navLinks.slideShow.main)}>
                    {this.props.translate("s_home")}
                    <i className={`mx-2 redText fa-solid fa-chevron-${this.GetIsMobile() ? 'down' : 'right'}`}></i>
                </div>
            }
            {Object.keys(this.props.path).map((key, i) => {

                let last = (i === this.props.path.length - 1);
                let onClick = (last) ? () => this.EmptyFunction() : () => this.props.HandleLinkClick(this.props.path[key].link);

                return <div key={key} className={`segment d-flex align-items-center ${last ? 'current' : ''}`} role={`${last ? '' : 'button'}`} onClick={onClick}>
                    {this.props.path[key].text}
                    {!last && <i className={`mx-2 redText fa-solid fa-chevron-${this.GetIsMobile() ? 'down' : 'right'}`}></i>}
                </div>
            })}
        </div>
    }
        
}
