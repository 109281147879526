
import React, { Component } from 'react';
import { NavBar } from './../shared/NavBar';
import { NotFound } from './../shared/NotFound';
import PageHelper from '../../model/helper/PageHelper';
import { Loading } from './../shared/Loading';
import { PageError } from './../shared/PageError';
import { Footer } from './../shared/Footer';
import { ArrowUp } from './../shared/ArrowUp';
import DOMPurify from 'dompurify';

//pages
import { Home } from './pages/home/Home';
import { AutomatedDispensariesDetail } from './pages/AutomatedDispensariesDetail';
import Career from './pages/Career';
import { Guest } from './pages/Guest';
import { Brochures } from './pages/Brochures';

export class PageController extends Component {
    static displayName = PageController.name;

    constructor(props) {
        super(props);
        this.helper = new PageHelper();
        this.state = {

            testYline: 0,

            loading: true,
            loadingSlideShow: this.props.page === 'home',
            slideShowData: [],
            allLanguages: [],
            defaultLanguageCode: null,
            currentLanguage: null,
            forceCollapseNav: 0,
            translations: [],
            currentSection: null,
            pageError: false,
            navLinks: {
                slideShow: {
                    showInNavbar: false,
                    showSubLinks: false,
                    main: { ref: React.createRef(), refName: 'homeSlideShow', translation: '', pageName: 'home', animate: false, animationShown: false },
                    subLinks: {}
                },
                ad: {
                    showInNavbar: true,
                    showSubLinks: true,
                    main: { ref: React.createRef(), refName: 'homeAd', translation: 's_navlink_dispensary', pageName: 'home', animate: true, animationShown: false },
                    subLinks: {
                        adDetail: { showInNavbar: true, ref: 's_dispensaries_page_url', refName: 'adDetail', translation: 's_dispensary_machines', pageName: 'automatedDispensaries' }
                    }
                },
                vm: {
                    showInNavbar: true,
                    showSubLinks: false,
                    main: { ref: React.createRef(), refName: 'homeVm', translation: 's_navlink_vending_machines', pageName: 'home', animate: true, animationShown: false },
                    subLinks: {
                        vmDetail: { ref: 's_vending_machines_page_url', refName: 'vmDetail', translation: '', pageName: 'vendingMachines' }
                    },
                },
                rvm: {
                    showInNavbar: true,
                    showSubLinks: false,
                    main: { ref: React.createRef(), refName: 'homeRvm', translation: 's_navlink_reverse_vending_machines', pageName: 'home', animate: true, animationShown: false },
                    subLinks: {
                        rvmDetail: { ref: 's_reverse_vending_machines_page_url', refName: 'rvmDetail', translation: '', pageName: 'reverseVendingMachines' }
                    },
                },
                aboutUs: {
                    showInNavbar: true,
                    showSubLinks: false,
                    main: { ref: React.createRef(), refName: 'homeAboutUs', translation: 's_navlink_about_us', pageName: 'home', animate: true, animationShown: false },
                    subLinks: {}
                },
                career: {
                    showInNavbar: true,
                    showSubLinks: false,
                    main: { ref: React.createRef(), refName: 'homeCareer', translation: 's_navlink_career', pageName: 'home', animate: true, animationShown: false },
                    subLinks: {
                        careerDetail: { ref: 's_career_page_url', refName: 'careerDetail', translation: '', pageName: 'career' }
                    }
                },
                contact: {
                    showInNavbar: true,
                    showSubLinks: false,
                    main: { ref: React.createRef(), refName: 'homeContact', translation: 's_navlink_contact', pageName: 'home', animate: true, animationShown: false },
                    subLinks: {
                        contactForm: { ref: React.createRef(), refName: 'homeContactForm', translation: '', pageName: 'home', animate: true, animationShown: false }
                    }
                },
                brochures: {
                    showInNavbar: true,
                    showSubLinks: false,
                    main: { ref: 's_brochures_page_url', refName: 'brochures', translation: 's_navlink_brochures', pageName: 'brochures' },
                    subLinks: {}
                }
            },
            scrollWaitTime: 220,
        }
    }

    componentDidMount() {

        this.GetLanguageList();

        if (this.props.page === 'home')
            this.InitHomePage();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.page != this.props.page) {
            if (this.props.page === 'home') {
                this.InitHomePage();
            } else {
                window.removeEventListener('scroll', this.HandleScroll);
            }

            this.GetTranslationList();
        }

        if (prevState.currentLanguage !== this.state.currentLanguage)
            this.GetTranslationList();

        // homepage, loading or loadingSlideShow changed and both loaded
        if (this.props.page === 'home' && (prevState.loading !== this.state.loading) || (prevState.loadingSlideShow !== this.state.loadingSlideShow)) {
            if (!this.state.loading && !this.state.loadingSlideShow) {
                this.HandleScroll();
                setTimeout(() => {
                    this.HomeScrollToSavedSection();
                }, this.state.scrollWaitTime);
            }
        }
    }

    InitHomePage = () => {
        window.addEventListener('scroll', this.HandleScroll);
        this.GetSlideShow();
    }

    GetTranslationList = () => {

        if (this.state.allLanguages === undefined)
            return;

        this.setState((prevState) => ({
            ...prevState,
            loading: true
        }));

        this.helper.getTranslationList(this.state.currentLanguage, this.state.allLanguages, this.props.page).then((translationsResponse) => {

            if (localStorage.getItem("debug"))
                console.log(translationsResponse);

            let error = translationsResponse.translations.length <= 0;
            this.setState((prevState) => ({
                ...prevState,
                pageError: error,
                translations: translationsResponse.translations,
                loading: false
            }));

        }).then(() => {
            this.helper.lsh.TryExtendDataExpireTime();
        });
    }

    HomeScrollToSavedSection = () => {

        let scrollTo = this.helper.lsh.GetScrollTo();
        if (scrollTo != '') {
            // looking for the scrollTo link in navLinks main
            let link = Object.values(this.state.navLinks).find(link => link.main.refName === scrollTo)?.main;

            // looking for the scrollTo link in navLinks main.sublinks
            if (!link) {
                Object.keys(this.state.navLinks).map(key => {
                    const subLinks = this.state.navLinks[key].subLinks;
                    let val = Object.values(subLinks).find(sl => sl.refName === scrollTo);
                    if (val) {
                        link = val;
                        return null;
                    }
                })
            }

            // success
            if (link) {
                setTimeout(() => {
                    this.ScrollIntoView(link.ref);
                    this.helper.lsh.DeleteScrollTo();
                    return;
                }, this.state.scrollWaitTime);
            }
        }
    }

    GetLanguageList = () => {
        this.helper.GetLanguageList().then((languagesResponse) => {

            if (localStorage.getItem("debug"))
                console.log(languagesResponse);

            if (languagesResponse.responseCode != 200)
                return this.setState((prevState) => ({
                    ...prevState,
                    pageError: true
                }));

            // setting all languages with images and a default language
            let allLanguages = languagesResponse.languages;
            let defaultLanguageCode = languagesResponse.languages[0].code;
            // getting current language (either cached or from users browser or default in this order)
            let currentLanguage = this.helper.GetLanguage(allLanguages, defaultLanguageCode);

            this.setState((prevState) => ({
                ...prevState,
                allLanguages: allLanguages,
                defaultLanguageCode: defaultLanguageCode,
                currentLanguage: currentLanguage
            }));
        });
    }

    GetSlideShow = () => {
        this.setState((prevState) => ({
            ...prevState,
            loadingSlideShow: true
        }));

        this.helper.getSlideShow().then((slideShowResponse) => {

            if (localStorage.getItem("debug"))
                console.log(slideShowResponse);

            this.setState((prevState) => ({
                ...prevState,
                slideShowData: slideShowResponse.slideShowList,
                loadingSlideShow: false
            }));
        });
    }

    HandleUseTranslationRaw = (key) => {

        if (key == null)
            return "";

        const translation = this.state.translations.find(obj => obj.key.toLowerCase() === key.toLowerCase());
        return translation ? translation.value : key;
    }

    HandleUseTranslation = (key) => {
        let translation = this.HandleUseTranslationRaw(key);
        //return <div className='loc' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(translation) }}></div>

        return <div className='loc' dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(translation, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'class'] })
        }}></div>
    }

    HandleLinkClick = (link) => {

        //console.log(link)
        if (link.pageName != 'home') {
            window.location = `/${this.HandleUseTranslationRaw(link.ref)}`;
            return;
        }

        if (this.props.page != 'home') {
            this.helper.lsh.SetScrollTo(link.refName);
            window.location = `/`;
            return;
        }

        if (this.GetIsMobile()) {

            this.setState((prevState) => ({
                ...prevState,
                forceCollapseNav: prevState.forceCollapseNav + 1
            }));

            setTimeout(() => {
                this.ScrollIntoView(link.ref);
            }, this.state.scrollWaitTime*2);
            return;
        } 

        this.ScrollIntoView(link.ref);
    };

    GetIsMobile = () => {
        return window.innerWidth <= 768;
    }

    ScrollIntoView = (ref) => {

        if (ref.current == null)
            return;

        let targetY = window.scrollY + ref.current.getBoundingClientRect().top - this.GetNavHeight();

        window.scrollTo({ top: targetY, behavior: "smooth" });
    }

    HandleChangeLanguage = (newLanguage) => {
        this.setState((prevState) => ({
            ...prevState,
            currentLanguage: newLanguage
        }));
    }

    GetNavHeight = () => {
        return window.screen.width <= 768 ? 61 : 70
    }

    GetLinkByRefName = (refName) => {
        for (const linkKey in this.state.navLinks) {
            let link = this.state.navLinks[linkKey];
            if (link.main.refName === refName)
                return link.main;

            for (const subLinkKey in link.subLinks) {
                let subLink = link.subLinks[subLinkKey];
                if (subLink.refName === refName)
                    return subLink;
            }
        }

        return null;
    }

    HandleAnimateSection = (refName) => {
        let section = this.GetLinkByRefName(refName);

        // If the section is not found, is external, not animatable, or already animated, do nothing
        if (section == null || section.pageName != 'home' || !section.animate || section.animationShown) {
            return;
        }

        // Clone the state deeply to avoid mutating the original state
        let newNavLinks = { ...this.state.navLinks };

        // Helper function to find and update the `animationShown` property
        const updateAnimationShown = (navLinks) => {
            for (let link in navLinks) {
                if (navLinks[link].main.refName === refName) {
                    navLinks[link].main.animationShown = true;
                    return;
                }
                // Check subLinks as well
                for (let subLink in navLinks[link].subLinks) {
                    if (navLinks[link].subLinks[subLink].refName === refName) {
                        navLinks[link].subLinks[subLink].animationShown = true;
                        return;
                    }
                }
            }
        };

        // Update the specific `animationShown` flag
        updateAnimationShown(newNavLinks);

        // Set the updated state
        this.setState((prevState) => ({
            ...prevState,
            navLinks: newNavLinks
        }));
    };

    HandleScroll = () => {

        let closestSection = this.GetClosestSection(0.50, true);
        //console.log(`closest section: ${closestSection}`);

        let closestSectionToAnimate = this.GetClosestSection(0.85, false);
        //console.log(`section to animate: ${closestSectionToAnimate}`);
        this.HandleAnimateSection(closestSectionToAnimate);

        //console.log(closestSection)
        this.setState((prevState) => ({
            ...prevState,
            currentSection: closestSection
        }));
    }

    GetClosestSection(viewportYThresholdPercentTop, forceSmallSectionAdjustment) {

        const screenTop = document.documentElement.scrollTop + this.GetNavHeight();
        const ScreenYThreshold = screenTop + ((window.innerHeight - this.GetNavHeight()) * viewportYThresholdPercentTop);

        //this.setState((prevState) => ({
        //    ...prevState,
        //    testYline: ScreenYThreshold
        //}));

        let closestSection = '';
        let minDistance = Number.MAX_SAFE_INTEGER;
        for (const sectionName in this.state.navLinks) {

            const section = this.state.navLinks[sectionName];
            const main = section.main;

            let dist;
            let subDist;
            if (main.pageName === 'home') {
                dist = this.GetSectionDistance(main, screenTop, ScreenYThreshold, forceSmallSectionAdjustment);
                if (dist <= minDistance) {
                    minDistance = dist;
                    closestSection = main.refName;
                }
            }

            for (const subSectionKey in section.subLinks) {
                let subSection = section.subLinks[subSectionKey];

                subDist = this.GetSectionDistance(subSection, screenTop, ScreenYThreshold, forceSmallSectionAdjustment);
                if (subDist <= minDistance) {
                    minDistance = subDist;
                    closestSection = subSection.refName;
                }
            }
        };

        return minDistance == Number.MAX_SAFE_INTEGER ? '' : closestSection;
    }

    GetSectionDistance = (section, screenTop, ScreenYThreshold, forceSmallSectionAdjustment) => {

        if (section.ref.current == undefined || section.ref.current == null)
            return Number.MAX_SAFE_INTEGER;
            
        const getOffsetTop = (element) => {
            let offsetTop = 0;
            while (element) {
                offsetTop += element.offsetTop;
                element = element.offsetParent;
            }
            return offsetTop;
        };

        const sectionTopY = getOffsetTop(section.ref.current);
        const sectionBottomY = sectionTopY + section.ref.current.clientHeight - 1;

        if (forceSmallSectionAdjustment) {
            //if sekce zacina na obrazovce a konci jeste nad thresholdem, je to currentsection a basta (koukám na tebe kariéro)
            if ((sectionTopY >= screenTop) && (sectionBottomY < ScreenYThreshold)) {
                return 0;
            }
        }

        let distanceTop = Math.abs(sectionTopY - ScreenYThreshold);
        let distanceBottom = Math.abs(sectionBottomY - ScreenYThreshold);

        const shorterDistance = distanceTop < distanceBottom ? distanceTop : distanceBottom;

        return shorterDistance;
    }

    GetPageContent = () => {

        switch (this.props.page) {
            case "home":
                return <Home translateRaw={this.HandleUseTranslationRaw} translate={this.HandleUseTranslation} language={this.state.currentLanguage} navLinks={this.state.navLinks} HandleLinkClick={this.HandleLinkClick} slideShow={this.state.slideShowData} />;
            case "career":
                return <Career translateRaw={this.HandleUseTranslationRaw} translate={this.HandleUseTranslation} language={this.state.currentLanguage} navLinks={this.state.navLinks} HandleLinkClick={this.HandleLinkClick} />;
            case "automatedDispensaries":
                return <AutomatedDispensariesDetail translateRaw={this.HandleUseTranslationRaw} translate={this.HandleUseTranslation} link={this.state.navLinks.ad} HandleLinkClick={this.HandleLinkClick} />;
            case "brochures":
                return <Brochures translateRaw={this.HandleUseTranslationRaw} translate={this.HandleUseTranslation} navLinks={this.state.navLinks} HandleLinkClick={this.HandleLinkClick} />
            //case "vendingMachines":
            //    return <VendingMachinesDetail translate={this.HandleUseTranslation} navLinks={this.state.navLinks} HandleLinkClick={this.HandleLinkClick} />;
            //case "reverseVendingMachines":
            //    return <ReverseVendingMachinesDetail translate={this.HandleUseTranslation} navLinks={this.state.navLinks} HandleLinkClick={this.HandleLinkClick} />;
            case "guest":
                return <Guest translateRaw={this.HandleUseTranslationRaw} translate={this.HandleUseTranslation} />;
            case "notFound":
            default:
                return <NotFound navLinks={this.state.navLinks} backLink={this.state.navLinks.slideShow} HandleLinkClick={this.HandleLinkClick} translateRaw={this.HandleUseTranslationRaw} translate={this.HandleUseTranslation} />;
        }
    }

    RenderCurrentPage() {

        return (
            <>
                <NavBar
                    currentLanguage={this.state.currentLanguage}
                    languagesImages={this.state.allLanguages}
                    translate={this.HandleUseTranslation}
                    changeLanguage={this.HandleChangeLanguage}
                    HandleLinkClick={this.HandleLinkClick}
                    navLinks={this.state.navLinks}
                    forceCollapse={this.state.forceCollapseNav}
                    currentSection={this.state.currentSection}
                    lsh={this.helper.lsh}
                    currentPage={this.props.page}
                />
                {this.GetPageContent()}
                {/*<div className='position-absolute w-100 border border-success' style={{ top: this.state.testYline + 'px' }}></div>*/}
                <Footer translate={this.HandleUseTranslation} HandleLinkClick={this.HandleLinkClick} navLinks={this.state.navLinks} />
                <ArrowUp />
            </>
        );
    }

    render() {
        return (
            <>
                {
                    (this.state.loading || this.state.loadingSlideShow) ?
                        <Loading blank white />
                        :
                        this.state.pageError ?
                            <PageError
                                currentLanguage={this.state.currentLanguage}
                                languagesImages={this.state.allLanguages}
                                ChangeLanguage={this.HandleChangeLanguage}
                                HandleLinkClick={this.HandleLinkClick}
                            />
                            :
                            this.RenderCurrentPage()
                }
            </>
        )
    }
}
