import React, { Component } from 'react'; 
import { Button } from './../../../../shared/Button';
import { Spinner } from './../../../../shared/Spinner';
import { InfoMessage } from './../../../../shared/InfoMessage';
import axios from "axios";
import AdmResponseHelper from './../../../../../model/helper/AdmResponseHelper';

export class NewRole extends Component {
    static displayName = NewRole.name;

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            nameEmpty: false,

            description: "",

            response: null,
            loading: false
        };

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        document.body.classList.add('noScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noScroll');
    }

    HandleSetName = (val) => {
        this.setState((prevState) => ({ ...prevState, name: val, nameEmpty: false, response: null }));
    }

    HandleSetDescription = (val) => {
        this.setState((prevState) => ({ ...prevState, description: val, response: null }));
    }

    CreateRole = () => {

        let nameEmpty = this.state.name.length <= 0;

        this.setState((prevState) => ({
            ...prevState,
            nameEmpty: nameEmpty,
            response: null,
            loading: (!nameEmpty)
        }));

        if (nameEmpty)
            return;

        axios
            .post("/api/adm/user/role/create", {
                LoginData: { Token: this.props.loggedAdmin.loginToken },
                Name: this.state.name,
                Description: this.state.description,
            }, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                this.setState((prevState) => ({ ...prevState, response: data.responseCode }));

                if (data.responseCode == 200) {
                    this.props.GetRoleList(true);
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 200);
                }
            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, response: 500 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    render() {
        return (
            <div className='form border rounded p-3 fitH position-relative'>

                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0'>Přidat roli</h2>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className='d-flex flex-column '>
                        <div className={`d-flex flex-column mb-3 col-12 ${this.state.nameEmpty ? 'inputError' : ''}`}>
                            <label htmlFor='name'>Název*</label>
                            <input type='text' id='name' className={`ps-2`} value={this.state.name} onChange={(e) => this.HandleSetName(e.target.value)} />
                        </div>
                        <div className={`d-flex flex-column mb-3 col-12`}>
                            <label htmlFor='description'>Popis</label>
                            <textarea type='text' id='description' className='ps-2' value={this.state.description} onChange={(e) => this.HandleSetDescription(e.target.value)} />
                        </div>
                    </div>
                    <div className={`d-flex justify-content-between`}>
                        <div className='d-flex flex-column'>
                            {this.responseHelper.RenderCreateResponse(this.state.response)}
                            {this.state.nameEmpty && <InfoMessage text={"Název role není vyplněn!"} />}
                        </div>
                        <Button className='col-2' text={"Přidat"} OnClick={() => this.CreateRole()} />
                    </div>
                </div>
            </div>
        );
    }

}
