import React, { Component } from 'react';
import './../../../styles/public/guest.scss';
import LoginHelper from './../../../model/helper/LoginHelper'
import { LoginForm } from './../../adm/pages/LoginForm';
import { Loading } from './../../shared/Loading';
import { Button } from './../../shared/Button';

export class Guest extends Component {
    static displayName = Guest.name;

    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
            user: null,
            loginExpired: false,
            loading: true,

            logOffResponse: null,
        };

        this.translate = this.props.translate;
        this.loginHelper = new LoginHelper(); 
    }

    componentDidMount() {
        this.TokenLogin();
    }

    TokenLogin = () => {

        this.loginHelper.GuestTokenLogin().then(tokenLoginResponse => {

            this.setState((prevState) => ({
                ...prevState,
                loginExpired: tokenLoginResponse.responseCode == 440,
                loggedIn: tokenLoginResponse.responseCode == 200,
                user: tokenLoginResponse.user,
                loading: false
            }));
        });
    }

    HandleLogOff = () => {
        this.loginHelper.GuestLogOff(this.state.user.loginToken).then(tokenLoginResponse => {
            if (tokenLoginResponse.responseCode == 200)
                return window.location.href = '/';            
        });
    }

    RenderLoggedContent = () => {

        if (!this.state.loggedIn)
            return;

        let canSeeDispensers = true;
        let canSeeVendingMachines = true;
        switch (this.state.user.roleName) {
            case 'guest_automated_dispensers':
                canSeeVendingMachines = false;
                break;
            case 'guest_vending_machines':
                canSeeDispensers = false;
                break;
            default: break;
        }

        return (
            <div className=''>
                <div className='col-12 d-flex justify-content-end'>
                    <Button text={this.props.translate("g_log_off")} OnClick={() => this.HandleLogOff()} />
                </div>
                {canSeeDispensers &&
                    <div className='mt-2 mb-4'>
                        <h1>{this.props.translate("s_navlink_dispensers")}</h1>
                        content
                    </div>
                }
                {canSeeVendingMachines &&
                    <div className='mt-2'>
                        <h1>{this.props.translate("s_navlink_vending_machines")}</h1>
                        content
                    </div>
                }
            </div>
        );
    }

    HandleSetLoggedUser = (guest) => {

        if (guest == null)
            return;

        this.setState((prevState) => ({ ...prevState, loggedIn: true, user: guest, logOffResponse: null }));
    }

    render() {
        return (
            <div id='guest' className='container'>
                {
                    this.state.loading ?
                        <Loading fitNav blank white />
                        :
                        this.state.loggedIn ?
                            <div>{this.RenderLoggedContent()}</div>
                            :
                            <LoginForm guest translate={this.props.translate} loginExpired={this.state.loginExpired} HandleSetLoggedUser={(u) => this.HandleSetLoggedUser(u)} />
                }
            </div>
        );
    }
}
