import React, { Component } from 'react';
import './../../../../../styles/public/home/aboutUs.scss'; 

export class AboutUs extends Component {
    static displayName = AboutUs.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section id='aboutUs' className={`d-flex flex-column ${this.props.link.main.animate && ' flyInAnimation'} ${(this.props.link.main.animate && this.props.link.main.animationShown) && 'flyIn'}`} ref={this.props.link.main.ref}>               
                <h1>{this.props.translate("s_navlink_about_us")}</h1>
                <div className='containerCustom'>
                    {this.props.translate("h_aboutus_text_1")}
                    <h2 className='mt-4'>Sielaff Bohemia s.r.o.</h2>
                    <div className='containerCustomSm'>
                        {this.props.translate("h_aboutus_text_2")}
                    </div>
                    <div className='d-flex flex-column mt-4'>
                        <h2 className=''>Sielaff Group</h2>
                        <div className='d-flex containerCustomSm flex-column flex-md-row'>
                            <div className='col'>
                                <h3 className=''>{this.props.translate("h_aboutus_subheading_1")}</h3>
                                <ul>
                                    {this.props.translate("h_aboutus_points_1")}
                                </ul>
                                <h3 className=''>{this.props.translate("h_aboutus_subheading_2")}</h3>
                                <ul>
                                    {this.props.translate("h_aboutus_points_2")}
                                </ul>
                            </div>
                            <div className='mapImg d-flex align-items-center justify-content-center'>
                                <img src='/files/images/about_us_map.png' alt='Mapa poboček po Evropě' className='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
