
import LocalStorageHandler from './LocalStorageHandler';
import LanguageListResponse from './../response/LanguageListResponse';
import TranslationListResponse from './../response/TranslationListResponse';
import CareerDetailResponse from './../response/CareerDetailResponse';
import CareerListResponse from './../response/CareerListResponse';
import axios from "axios";
import SlideShowListResponse from '../response/SlideShowListResponse';

class PageHelper {

    sharedTranslationsCached : Boolean;
    lsh = new LocalStorageHandler();
    sharedTranslationsData = [];

    //get languages
    GetLanguageList = async () => {

        let cachedLanguages = this.lsh.GetLanguages();
        if (cachedLanguages.responseCode === 200) {
            if (localStorage.getItem("debug"))
                console.log("languages loaded from cache!")

            return cachedLanguages;
        }

        let data;
        try {
            // loading data from db 
            const response = await axios.get('api/public/language/list');
            data = response.data;

            if (data.responseCode == 200)
                this.lsh.SetLanguages(data.languages);

        } catch {
            return new LanguageListResponse([], 500);
        }

        return new LanguageListResponse(data.languages, data.responseCode);
    }

    getSlideShow = async () => {

        let cachedSlideShow = this.lsh.GetSlideShow();
        if (cachedSlideShow.responseCode === 200) {
            if (localStorage.getItem("debug"))
                console.log("slideshow loaded from cache!")
            return cachedSlideShow;
        }

        let data;
        try {
            // loading data from db 
            const response = await axios.get('api/public/slideshow/list');
            data = response.data;

            if (data.responseCode == 200)
                this.lsh.SetSlideShow(data.slideList);

        } catch {
            return new SlideShowListResponse([], 500);
        }

        return new SlideShowListResponse(data.slideList, 200);
    }

    getTranslationList = async (language, allLanguages, page) => {

        // user changed language -> del old cached translations
        //if (language != this.lsh.GetLanguage(allLanguages))
        //    this.lsh.DeleteTranslations();

        this.sharedTranslationsCached = false;
        let loadFromCache = language === this.lsh.GetLanguage(allLanguages);
        if (loadFromCache) {

            this.sharedTranslationsData = this.lsh.GetTranslations(language ,'shared');
            this.sharedTranslationsCached = this.sharedTranslationsData.responseCode === 200;

            if (this.sharedTranslationsCached) {

                if (localStorage.getItem("debug"))
                    console.log(`translations/${language}/shared loaded from cache!`);

                // notFound uses only shared
                if (page == 'notFound')
                    return this.sharedTranslationsData;

                let cachedPageTranslations = this.lsh.GetTranslations(language, page);
                if (cachedPageTranslations.responseCode === 200) {
                    if (localStorage.getItem("debug"))
                        console.log(`translations/${language}/${page} loaded from cache!`);
                    let mergedTranslations = this.sharedTranslationsData.translations.concat(cachedPageTranslations.translations);
                    return new TranslationListResponse(mergedTranslations, 200);
                }
            }
        }

        if (!this.sharedTranslationsCached) {

            try {

                // loading shared translations from db 
                const sharedTranslationsResponse = await axios.get(`api/public/translation/list/${language}/shared`);
                this.sharedTranslationsData = sharedTranslationsResponse.data;

                if (this.sharedTranslationsData.responseCode != 200)
                    return this.sharedTranslationsData;

                this.lsh.SetTranslations(language, 'shared', this.sharedTranslationsData.translations);
            } catch (err) {
                console.log(err);
                return new TranslationListResponse([], 500);
            }
        }

        this.lsh.SetLanguage(language);

        // page notFound uses only shared translations
        if (page == 'notFound')
            return this.sharedTranslationsData;
            
        // loading page translations from db 
        const pageTranslationsReponse = await axios.get(`api/public/translation/list/${language}/${page}`);
        const pageTranslationsData = pageTranslationsReponse.data;

        if (pageTranslationsData.responseCode != 200)
            return pageTranslationsData;

        this.lsh.SetTranslations(language, page, pageTranslationsData.translations);

        let mergedTranslations = this.sharedTranslationsData.translations.concat(pageTranslationsData.translations);
        return new TranslationListResponse(mergedTranslations, pageTranslationsData.responseCode);
    }

    GetLanguage = (allLanguages, defaultLanguage) => {

        let cachedLanguage = this.lsh.GetLanguage(allLanguages);
        if (cachedLanguage != null)
            return cachedLanguage;

        let browserLanguage = navigator.language.substring(0, 2).toLowerCase();
        browserLanguage = allLanguages.find(l => l.code.toLowerCase() === browserLanguage);
        if (browserLanguage) {
            this.lsh.SetLanguage(browserLanguage.code);
            return browserLanguage.code;
        }

        return defaultLanguage;
    }

    GetCareerList = async (language) => {

        // loading career list from db 
        const careerResponse = await axios.get(`/api/public/career/list/${language}`);
        const careerReponseData = careerResponse.data;

        return new CareerListResponse(careerReponseData.careerList, careerReponseData.responseCode);
    }

    GetCareerDetail = async (language, name) => {
        try {
            const response = await axios.get(`/api/public/career/${language}/${name}`);
            let data = response.data;
            return new CareerDetailResponse(data.career, data.responseCode);
        } catch (error) {
            return new CareerDetailResponse(null, 500);
        }
    }

}

export default PageHelper;