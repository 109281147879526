import React, { Component } from 'react'; 
import { Button } from './../../../../shared/Button';
import axios from "axios";
import { Spinner } from './../../../../shared/Spinner';
import AdmResponseHelper from './../../../../../model/helper/AdmResponseHelper';
import { InfoMessage } from './../../../../shared/InfoMessage';
import { SliderOnOff } from './../../../../shared/SliderOnOff';

export class EditLanguage extends Component {
    static displayName = EditLanguage.name;

    constructor(props) {
        super(props);

        this.oldTarget = this.props.languageList.find(l => l.id === this.props.targetId);
        this.oldLanguageList = this.props.languageList;

        this.state = {

            target: this.oldTarget,

            responseEdit: null,
            responseDelete: null,
            loading: false,

            showDeleteConfirmation: false,

            defaultAndNotActive: false,
            nameEmpty: false,
            codeEmpty: false,
        };

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidUpdate() {

        if (JSON.stringify(this.props.languageList) != JSON.stringify(this.oldLanguageList)) {

            let newTarget = this.props.languageList.find(l => l.id === this.props.targetId);
            if (newTarget == null)
                return;

            this.oldTarget = newTarget;
            this.oldLanguageList = this.props.languageList;
            this.setState({ ...this.state, target: newTarget });
        } 
    }

    DeleteLanguage = () => {

        this.setState((prevState) => ({ ...prevState, loading: true }));

        axios
            .delete(`/api/adm/language/delete/${this.state.target.id}`, this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.setState({ responseDelete: data.responseCode });

                if (data.responseCode == 200) {
                    this.props.GetLanguageList();
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 200);
                }

            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, responseDelete: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    EditLanguage = () => {

        let defaultAndNotActive = this.state.target.isDefault && !this.state.target.isActive;
        let nameEmpty = this.state.target.name.length <= 0;
        let codeEmpty = this.state.target.code.length <= 0;

        this.setState((prevState) => ({
            ...prevState,
            loading: !defaultAndNotActive && !nameEmpty && !codeEmpty,
            defaultAndNotActive: defaultAndNotActive,
            nameEmpty: nameEmpty,
            codeEmpty: codeEmpty
        }));

        if (defaultAndNotActive || nameEmpty || codeEmpty)
            return;

        axios
            .patch("/api/adm/language/edit", {
                Id: this.state.target.id,
                Name: this.state.target.name,
                Code: this.state.target.code,
                IsActive: this.state.target.isActive,
                IsDefault: this.state.target.isDefault,
                ImgPath: this.state.target.imgPath
            }, this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.props.GetLanguageList();
                this.setState({ ...this.state, responseEdit: data.responseCode });
            }).catch(() => {
                this.setState({ ...this.state, responseEdit: 500 });
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });;
    }

    render() {
        return (
            <div className='form border rounded p-3 fitH'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0'>Upravit jazyk</h2>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className={`d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='id'>ID <i className="ms-2 fa-solid fa-lock"></i></label>
                        <input type='text' id='id' name='id' className='ps-2 readonly' readOnly value={this.state.target.id} />
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12 ${this.state.nameEmpty && 'inputError'}`}>
                        <label htmlFor='name'>Název</label>
                        <input type='text' id='name' name='name' className='ps-2' value={this.state.target.name} onChange={(e) => this.HandleSetName(e.target.value)} />
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12 ${this.state.codeEmpty && 'inputError'}`}>
                        <label htmlFor='code'>Kód</label>
                        <input type='text' id='code' name='code' className='ps-2' value={this.state.target.code} onChange={(e) => this.HandleSetCode(e.target.value)} />
                    </div>
                    <div className='d-flex align-items-center mb-2'>
                        <div className='border-end pe-3'>
                            <SliderOnOff text={'Aktivní'} isChecked={this.state.target.isActive} OnChange={() => this.HandleToggleIsActive()} />
                        </div>
                        <div className='ps-3'>
                            <SliderOnOff text={'Výchozí'} isChecked={this.state.target.isDefault} OnChange={() => this.HandleToggleIsDefault()} />
                        </div>
                    </div>
                    <div className={`position-relative d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='imgPath'>Název obrázku</label>
                        <input type='text' id='imgPath' name='imgPath' className='ps-2' value={this.state.target.imgPath} onChange={(e) => this.HandleSetImgPath(e.target.value)} />
                        <img className='position-absolute langImg' src={'/files/images/' + this.state.target.imgPath} alt='Obrázek jazyka' />
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                            <Button className='col-2' text={this.state.showDeleteConfirmation ? 'Zrušit' : 'Smazat'} OnClick={() => this.HandleToggleDeleteConfirmation()} />
                            {this.state.showDeleteConfirmation &&
                                <div className='ms-1'><Button className='col-2 m-5' text={'Opravdu smazat'} OnClick={() => this.DeleteLanguage()} /></div>
                            }
                        </div>
                        <Button className='col-2' text={"Upravit"} OnClick={() => this.EditLanguage()} />
                    </div>
                    <div className='d-flex flex-column mb-2 mt-3'>
                        {this.state.showDeleteConfirmation &&
                            this.responseHelper.RenderCascadeDeleteWarning()
                        }
                        {this.responseHelper.RenderDeleteResponse(this.state.responseDelete)}
                        {this.responseHelper.RenderEditResponse(this.state.responseEdit)}
                        {this.state.defaultAndNotActive && <InfoMessage text={'Neaktivní jazyk nelze nastavit jako výchozí.'} />}
                        {this.state.codeEmpty && <InfoMessage text={'Vyplňte kód!'} />}
                        {this.state.nameEmpty && <InfoMessage text={'Vyplňte název!'} />}
                    </div>
                </div>
            </div>
        );
    }

    HandleToggleDeleteConfirmation = () => {
        this.HandleClearResponses();
        this.setState((prevState) => ({ ...prevState, showDeleteConfirmation: !this.state.showDeleteConfirmation }));
    }

    HandleToggleIsActive = () => {
        this.HandleClearResponses();
        this.setState((prevState) => ({
            prevState,
            target: {
                ...this.state.target,
                isActive: !this.state.target.isActive,
                isDefault: this.state.target.isActive ? false : this.state.target.isDefault,
            },
            defaultAndNotActive: false
        }));
    }

    HandleToggleIsDefault = () => {
        this.HandleClearResponses();
        this.setState((prevState) => ({
            prevState,
            target: {
                ...this.state.target,
                isDefault: !this.state.target.isDefault,
                isActive: this.state.target.isDefault ? this.state.target.isActive : true
            },
            defaultAndNotActive: false
        }));
    }

    HandleSetName = (val) => {
        this.HandleClearResponses();
        this.setState((prevState) => ({
            prevState,
            target: {
                ...this.state.target,
                name: val
            },
        }));
    }

    HandleSetCode = (val) => {
        this.HandleClearResponses();
        this.setState((prevState) => ({
            prevState,
            target: {
                ...this.state.target,
                code: val
            },
        }));
    }

    HandleSetImgPath = (val) => {
        this.HandleClearResponses();
        this.setState((prevState) => ({
            prevState,
            target: {
                ...this.state.target,
                imgPath: val
            },
        }));
    }

    HandleClearResponses = () => {
        this.setState((prevState) => ({
            ...prevState,
            responseEdit: null,
            defaultAndNotActive: false,
            nameEmpty: false
        }));
    }

}
