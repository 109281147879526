import React, { Component } from 'react';
import { Spinner } from './../../../../shared/Spinner';
import { Button } from './../../../../shared/Button';

export class SlideShowList extends Component {
    static displayName = SlideShowList.name;

    constructor(props) {
        super(props);
        this.state = {
            showDescription: false
        }
    }

    componentDidMount() {

    }

    HandleToggleDescription = () => {
        this.setState((prevState) => ({ ...prevState, showDescription: !this.state.showDescription }));
    }

    RenderSlideList = () => {
        return (
            <div className='listView'>
                <div className='col-12 d-flex mt-3'>
                    <div className='col-2 fw-bold'>ID</div>
                    <div className='col-4 fw-bold'>Img</div>
                    <div className='col-2 fw-bold'>Pořadí</div>
                    <div className='col-3 fw-bold'>Aktivní</div>
                </div>
                <div className='col-12 slideList mb-4'>
                    {this.props.slideList.map((slide) => {
                        return <div className='col-12 d-flex border-top border-bottom align-items-center py-2' role='button' key={slide.id} onClick={() => this.props.HandleOpenEditSlide(slide)}>
                            <div className='col-2'>{slide.id}</div>
                            <div className='col-4'>
                                <img src={`/files/images/${slide.imgPc}`} alt='' className='' />
                            </div>
                            <div className='col-2'>{slide.order}</div>
                            <div className='col-3 '>
                                {slide.isActive ?
                                    <div className='d-flex align-items-center'>
                                        Ano
                                        <div className={`colorCircle green ms-1`}></div>
                                    </div>
                                    :
                                    <div className='d-flex align-items-center'>
                                        Ne
                                        <div className={`colorCircle grayDarker ms-1`}></div>
                                    </div>
                                }
                            </div>
                        </div>
                    })}
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className='d-flex flex-column'>
                    <div className='mt-3 d-flex flex-column flex-sm-row align-items-sm-center'>
                        <h1 className='m-0'>Správa slideshow</h1>
                        <div className='d-flex justify-content-end ms-sm-4'>
                            <Button text={" + Přidat"} OnClick={() => this.props.HandleOpenCreateSlide()} />
                        </div>
                    </div>
                    <div className='mt-2' onClick={() => this.HandleToggleDescription()} role='button'>
                        <div className='d-flex align-items-center'>
                            <h3 className='m-0 me-2'>Popis funkčnosti</h3>
                            <i className={`fa-solid fa-chevron-${this.state.showDescription ? 'down' : 'up'}`}></i>
                        </div>
                        {this.state.showDescription &&
                            <ul className='mt-2'>
                                <li>Ve slideshow veřejné části jsou pouze aktivní slidy. Textové popisky se zkládají z vytvořených překladů vázaných na stránku 'slideshow' a jsou nepovinné.</li>
                                <li>V případě jen jednoho aktivního slidu ve slideshow, se kontrolní prvky nezobrazují. V případě žádných slidů se na public stránce slideshow nezobrazí vůbec.</li>                                
                            </ul>
                        }

                    </div>
                </div>
                <div className='mt-4'>
                    <div className='position-relative'>
                        {this.props.slideListLoading ?
                            <Spinner dark />
                            :
                            this.RenderSlideList()
                        }
                    </div>
                </div>
            </>   
        );
    }
}
