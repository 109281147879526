import React, { Component } from 'react';
import './../../../styles/adm/loginForm.scss'; 
import { Spinner } from './../../shared/Spinner';
import LoginController from './../../../model/helper/LoginHelper';
import { InfoMessage } from './../../shared/InfoMessage';
import { Button } from './../../shared/Button';
import { BackButton } from './../../shared/BackButton';

export class LoginForm extends Component {
    static displayName = LoginForm.name;

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            usernameEmpty: false,
            password: "",
            passwordTooShort: false,
            passwordVisible: false,
            loading: false,
            loggedIn: false,
            response: null
        }

        this.passwordMinLength = 6;
        this.loginController = new LoginController();
    }

    HandleTogglePassword = () => {
        this.setState({ ...this.state, passwordVisible: !this.state.passwordVisible });
    }

    HandleSetUsername = (username) => {
        this.setState({ ...this.state, username: username, usernameEmpty: false });
    }

    HandleSetPassword = (password) => {
        this.setState({ ...this.state, password: password, passwordTooShort: false });
    }

    Login = () => {

        let usernameEmpty = this.state.username == "";
        let passwordTooShort = this.state.password.length < this.passwordMinLength;

        this.setState((prevState) => ({
            ...prevState,
            usernameEmpty: usernameEmpty,
            passwordTooShort: passwordTooShort
        }));

        if (usernameEmpty || passwordTooShort)
            return;

        this.setState({ ...this.state, loading: true });

        if (this.props.guest) {
            this.loginController.GuestCredentialsLogin(this.state.username, this.state.password).then((loginResponse) => {

                if (loginResponse.responseCode == 200)
                    this.props.HandleSetLoggedUser(loginResponse.user)

                this.setState((prevState) => ({
                    ...prevState,
                    loading: false,
                    password: "",
                    passwordTooShort: false,
                    usernameEmpty: false,
                    response: loginResponse.responseCode
                }));
            });
        } else {
            this.loginController.AdminCredentialsLogin(this.state.username, this.state.password).then((loginResponse) => {

                if (loginResponse.responseCode == 200)
                    this.props.HandleSetLoggedUser(loginResponse.user)

                this.setState((prevState) => ({
                    ...prevState,
                    loading: false,
                    password: "",
                    passwordTooShort: false,
                    usernameEmpty: false,
                    response: loginResponse.responseCode
                }));
            });
        }
    }

    RenderResponse = () => {

        switch (this.state.response) {
            case 200:
                return <InfoMessage text={this.props.guest ? this.props.translate("g_login_form_login_success") : 'Přihlášení proběhlo úspěšně!'} isSuccess />;
            case 400:
            case 401:
                return <InfoMessage text={this.props.guest ? this.props.translate("g_login_form_login_invalid_credentials") : 'Neplatné přihlašovací údaje!'} />;
            case 500:
                return <InfoMessage maxW text={this.props.guest ? `${this.props.translate("s_something_went_wrong")}${this.props.translate("s_wait_and_try_again")}` : 'Něco se nepovedlo. Zkuste to později.'} />;
            default:
                return;
        }
    }

    InputOnPressEnter = (event) => {
        if (event.key === 'Enter')
            this.Login();
    }

    render() {
        return (
            <div id="loginForm" className='d-flex justify-content-center align-items-center flex-column container'>
                <div className='position-realtive d-flex flex-column justify-content-center align-items-center p-4 pt-5 p-lg-5 pb-lg-4 rounded position-relative'>
                    <div className='position-absolute d-flex justify-content-start w-100 top-0 left-0 p-3'>
                        <BackButton isAdm home text={'Back to the main page'} HandleLinkClick={() => window.location.href = '/'} />
                    </div>
                    {this.props.loginExpired &&
                        <div className='position-absolute bottom-100 start-0'>
                            {<InfoMessage text={"Přihlášení vypršelo!"} />}
                        </div>}
                    <h2 className='mb-4 mt-3 mx-auto'>{this.props.guest ? this.props.translate("g_login_form_heading") : 'Administrace'}</h2>
                    <div className='fitW d-flex flex-column'>
                        <div className='mb-2 d-flex align-items-center mx-auto'>
                            <i className="fa-solid fa-user me-2"></i>
                            <input
                                className={`p-1 ${this.state.usernameEmpty ? 'inputError' : ''}`}
                                type='text'
                                placeholder={this.props.guest ? this.props.translate("s_name") : 'Jméno'}
                                value={this.state.username}
                                onKeyDown={(e) => this.InputOnPressEnter(e)}
                                onChange={(e) => this.HandleSetUsername(e.target.value)}
                            />
                        </div>
                        <div className='mb-2 d-flex align-items-center mx-auto position-relative'>
                            <i className="fa-solid fa-lock me-2"></i>
                            <input
                                className={`p-1 ${this.state.passwordTooShort ? 'inputError' : ''}`}
                                type={`${this.state.passwordVisible ? 'text' : 'password'}`}
                                placeholder={this.props.guest ? this.props.translate("g_login_form_password") : 'Heslo'}
                                value={this.state.password}
                                onKeyDown={(e) => this.InputOnPressEnter(e)}
                                onChange={(e) => this.HandleSetPassword(e.target.value)}
                            />
                            <i
                                className={`toggler fa-regular fa-eye${this.state.passwordVisible ? '-slash' : ''}`}
                                onClick={() => this.HandleTogglePassword()}
                            ></i>
                        </div>
                        <div className='d-flex justify-content-end mt-1 mb-2'>
                            <Button text={this.props.guest ? this.props.translate("g_login_form_login") : 'Přihlásit'} OnClick={() => this.Login()} />
                        </div>
                        <div className='d-flex text-wrap'>{this.RenderResponse()}</div>
                        {this.state.usernameEmpty && <InfoMessage text={this.props.guest ? this.props.translate("g_login_form_username_empty") : 'Přihlašovací jméno není vyplněno!'} stayVisible />}
                        {this.state.passwordTooShort && <InfoMessage text={this.props.guest ? this.props.translate("g_login_form_password_too_short") : 'Heslo je příliš krátké!'} stayVisible />}
                    </div>
                    {this.state.loading && <Spinner absolute whiteBg />}
                </div>
            </div>
        );
    }
}
