
import React, { Component } from 'react'; 
import AdmHelper from './../../model/helper/AdmHelper';
 

export class SelectTranslation extends Component {
    static displayName = SelectTranslation.name;

    constructor(props) {
        super(props);

        this.state = {
            
        };

        this.helper = new AdmHelper();
    }

    render() {

        let target = this.props.translationList.find(t => t.id == this.props.selectedId);
        let colorCircle;
        if (!target) {
            colorCircle = this.props.optional ? <div className={`colorCircle grayDarker ms-1`}></div> : <div className={`colorCircle red ms-1`}></div>;
        } else {
            colorCircle = ((!target.isActive || !target.isComplete) && !this.props.optional) ? <div className={`colorCircle red ms-1`}></div> : <div className={`colorCircle green ms-1`}></div>;
        }
            
        return (
            <div className={`d-flex flex-column ${this.props.mb2 ? 'mb-2' : 'mb-3'} col ${(this.props.selectedId == 'default' && !this.props.optional) && 'inputError'}`}>
                <label htmlFor={this.props.text}>{this.props.text}</label>
                <div className='d-flex align-items-center justify-content-between'>
                    <select type='text' id={this.props.text} className={`ps-2 me-1 col mw-100`} value={this.props.selectedId} onChange={(e) => this.props.OnChange(e.target.value)}>
                        <option value={'default'}>{this.props.optional ? 'Vyberte (nepovinné)' : 'Vyberte'}</option>
                        {this.props.translationList.map(translation => {
                            return (
                                <option key={translation.id} value={translation.id}>{translation.key} {this.props.withNote && `-> ${this.helper.GetLocalizationCzechOrOtherShort(translation)}`} {!translation.isActive && '(Neaktivní)'} {!translation.isComplete && '(Nekompletní)'}</option>
                            );
                        })}
                    </select>
                    {colorCircle}
                </div>
            </div>
        );
    }

}
