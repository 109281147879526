import React, { Component } from 'react';
import './../../../../../styles/public/home/automatedDispensaries.scss'; 
import { Button } from '../../../../shared/Button';

export class AutomatedDispensaries extends Component {
    static displayName = AutomatedDispensaries.name;

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <section id='dispensaries'
                className={`position-relative d-flex justify-content-between ${this.props.link.main.animate && 'flyInAnimation'} ${(this.props.link.main.animate && this.props.link.main.animationShown) && 'flyIn'}`}
                ref={this.props.link.main.ref}
            >
                <div className='d-flex flex-column col-12 col-md-7 col-xl-6'>
                    <h1 className='d-flex align-items-center'>
                        <img src='/files/images/tb.png' alt='logo TB' className='me-3' />
                        {this.props.translate("s_navlink_dispensary")}
                    </h1>
                    <div className='d-flex flex-column containerCustom'>
                        {this.props.translate("h_ad_text")}
                        <h2 className='mt-2'>{this.props.translate("h_ad_subheading")}</h2>
                        <div className='containerCustom'>
                            <ul>
                                {this.props.translate("h_ad_points")}
                            </ul>
                        </div>
                        <a href={`/${this.props.translateRaw("s_dispensaries_page_url")}`} className='mt-2'>
                            <Button text={this.props.translate("s_dispensary_machines")} arrow />
                        </a>
                    </div>
                </div>
                <div className='bgImg d-none d-md-flex col'></div>
            </section>
        );
    }
}
