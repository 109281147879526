import React, { Component } from 'react'; 
import { Button } from './../../../../shared/Button';
import { Spinner } from './../../../../shared/Spinner';
import { InfoMessage } from './../../../../shared/InfoMessage';
import axios from "axios";
import AdmResponseHelper from './../../../../../model/helper/AdmResponseHelper';
import { SliderOnOff } from './../../../../shared/SliderOnOff';
import { SelectTranslation } from './../../../../shared/SelectTranslation';

export class NewCareer extends Component {
    static displayName = NewCareer.name;

    constructor(props) {
        super(props);

        this.state = {
            internalNote: "",
            internalNoteEmpty: false,
            active: false,

            response: null,
            loading: false,

            selectedNameUrlId: 'default',
            selectedNameId: 'default',
            selectedBranchId: 'default',
            selectedLocationId: 'default',
            selectedDescriptionId: 'default',
            selectedAdditionalInformationId: 'default',
            selectedBenefitsId: 'default',

            selectedNameUrlEmpty: false,
            selectedNameEmpty: false,
            selectedBranchEmpty: false,
            selectedLocationEmpty: false,
            selectedDescriptionEmpty: false,
        };

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        document.body.classList.add('noScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noScroll');
    }

    HandleSetInternalNote = (val) => {
        this.setState((prevState) => ({ ...prevState, internalNote: val, response: null, internalNoteEmpty: false }));
    }

    HandleSetName = (val) => {
        this.setState((prevState) => ({ ...prevState, name: val, response: null, nameEmpty: false, nameHasSpace: this.state.name.includes(' ') }));
    }

    HandleToggleActive = () => {
        this.setState((prevState) => ({ ...prevState, active: !this.state.active, response: null }));
    }

    ValidateSelects = () => {
        let internalNoteEmpty = this.state.internalNote.length <= 0;
        let selectedNameUrlEmpty = this.state.selectedNameUrlId == 'default';
        let selectedNameEmpty = this.state.selectedNameId == 'default';
        let selectedBranchEmpty = this.state.selectedBranchId == 'default';
        let selectedLocationEmpty = this.state.selectedLocationId == 'default';
        let selectedDescriptionEmpty = this.state.selectedDescriptionId == 'default';

        this.setState((prevState) => ({
            ...prevState,
            internalNoteEmpty: internalNoteEmpty,
            selectedNameUrlEmpty: selectedNameUrlEmpty,
            selectedNameEmpty: selectedNameEmpty,
            selectedBranchEmpty: selectedBranchEmpty,
            selectedLocationEmpty: selectedLocationEmpty,
            selectedDescriptionEmpty: selectedDescriptionEmpty,
        }));

        return !(
            internalNoteEmpty ||
            selectedNameUrlEmpty ||
            selectedNameEmpty ||
            selectedBranchEmpty ||
            selectedLocationEmpty ||
            selectedDescriptionEmpty
        );
    }

    CreateCareer = () => {

        if (!this.ValidateSelects())
            return;

        this.setState((prevState) => ({ ...prevState, loading: true }));

        axios
            .post("/api/adm/career/create", {
                LoginData: { Token: this.props.loggedAdmin.loginToken },
                InternalNote: this.state.internalNote,
                IsActive: this.state.active,
                IdTranslationNameUrl: this.state.selectedNameUrlId,
                IdTranslationName: this.state.selectedNameId,
                IdTranslationBranch: this.state.selectedBranchId,
                IdTranslationLocation: this.state.selectedLocationId,
                IdTranslationDescription: this.state.selectedDescriptionId,
                IdTranslationAdditionalInformation: this.state.selectedAdditionalInformationId == 'default' ? null : this.state.selectedAdditionalInformationId,
                IdTranslationBenefits: this.state.selectedBenefitsId == 'default' ? null : this.state.selectedBenefitsId
            }, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                this.setState((prevState) => ({ ...prevState, response: data.responseCode }));

                if (data.responseCode == 200) {
                    this.props.GetCareerList();
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 200);
                }
            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, response: 500 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    HandleSetSelectedNameUrl = (val) => {
        this.setState((prevState) => ({ ...prevState, selectedNameUrlId: val, selectedNameUrlEmpty: false }));
    }

    HandleSetSelectedName = (val) => {
        this.setState((prevState) => ({ ...prevState, selectedNameId: val, selectedNameEmpty: false }));
    }

    HandleSetSelectedBranch = (val) => {
        this.setState((prevState) => ({ ...prevState, selectedBranchId: val, selectedBranchEmpty: false }));
    }

    HandleSetSelectedLocation = (val) => {
        this.setState((prevState) => ({ ...prevState, selectedLocationId: val, selectedLocationEmpty: false }));
    }

    HandleSetSelectedDescription = (val) => {
        this.setState((prevState) => ({ ...prevState, selectedDescriptionId: val, selectedDescriptionEmpty: false }));
    }

    HandleSetSelectedAdditionalInformation = (val) => {
        this.setState((prevState) => ({ ...prevState, selectedAdditionalInformationId: val }));
    }

    HandleSetSelectedBenefits = (val) => {
        this.setState((prevState) => ({ ...prevState, selectedBenefitsId: val }));
    }

    render() {
        return (
            <div className='form border rounded p-3 fitH'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0'>Přidat kariéru</h2>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className='my-2 mb-3'>
                        <SliderOnOff text={'Aktivní'} isChecked={this.state.active} OnChange={() => this.HandleToggleActive()} />
                    </div>
                    <div className='d-flex flex-column'>
                        <div className={`d-flex flex-column mb-3 col-12 ${this.state.internalNoteEmpty && 'inputError'}`}>
                            <label htmlFor='description'>Interní popis</label>
                            <textarea type='text' id='description' name='description' className='ps-2' value={this.state.internalNote} onChange={(e) => this.HandleSetInternalNote(e.target.value)} />
                        </div>
                    </div>
                    {<SelectTranslation withNote text={'Překlad url* (url friendly)'} selectedId={this.state.selectedNameUrlId} OnChange={this.HandleSetSelectedNameUrl} translationList={this.props.translationList} />}
                    {<SelectTranslation withNote text={'Překlad názvu*'} selectedId={this.state.selectedNameId} OnChange={this.HandleSetSelectedName} translationList={this.props.translationList} />}
                    {<SelectTranslation withNote text={'Překlad odvětví*'} selectedId={this.state.selectedBranchId} OnChange={this.HandleSetSelectedBranch} translationList={this.props.translationList} />}
                    {<SelectTranslation withNote text={'Překlad lokace*'} selectedId={this.state.selectedLocationId} OnChange={this.HandleSetSelectedLocation} translationList={this.props.translationList} />}
                    {<SelectTranslation withNote text={'Překlad popisu*'} selectedId={this.state.selectedDescriptionId} OnChange={this.HandleSetSelectedDescription} translationList={this.props.translationList} />}
                    {<SelectTranslation withNote text={'Překlad dodatečných informací'} selectedId={this.state.selectedAdditionalInformationId} OnChange={this.HandleSetSelectedAdditionalInformation} optional translationList={this.props.translationList} />}
                    {<SelectTranslation withNote text={'Překlad benefitů'} selectedId={this.state.selectedBenefitsId} OnChange={this.HandleSetSelectedBenefits} optional translationList={this.props.translationList} />}               
                    <div className={`d-flex justify-content-between`}>
                        <div>
                            {this.state.internalNoteEmpty && <InfoMessage text={"Vyplňte popis!"} />}
                            {this.responseHelper.RenderCreateResponse(this.state.response)}
                            {
                                (
                                this.state.selectedNameUrlEmpty ||
                                this.state.selectedNameEmpty ||
                                this.state.selectedBranchEmpty ||
                                this.state.selectedLocationEmpty ||
                                this.state.selectedDescriptionEmpty)
                                && <InfoMessage text={"Vyberte všechny povinné překlady!"} />
                            }
                        </div>
                        <Button className='col-2' text={"Přidat"} OnClick={() => this.CreateCareer()} />
                    </div>
                </div>
            </div>
        );
    }

}
